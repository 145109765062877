<script>
  import { conf, routeU } from './stores.js'
  import { init, i18n, i18nBr, nl2br, api, setRoute, isRoute, thisYear, asArray, check, cdn, openHref, btoaUnicode, fuc, platform, dateTimeFormat, u, setCookie, getCookie } from './h.js'

  import { fade } from 'svelte/transition'

  import Route from './G_Route.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'
  import Navbar from './L_Navbar.svelte'
  import Content from './L_Content.svelte'
  import CustomRender from './G_CustomRender.svelte'
  import Msg from './L_Msg.svelte'
  import Footer from './L_Footer.svelte'
  import Video from './G_Video.svelte'
  import Scanner from './G_Scanner.svelte'
  import Pub from './L_Pub.svelte'
  import ScratchCard from './G_ScratchCard.svelte'
  import Map from './L_Map.svelte'
  import PdfImg from './G_PdfImg.svelte'

  export let isDesign=false
  export let editItemIndex=null

  export let code=false

  $: isPreview=$conf.preview
  
  let version="4.0.1"
  init({faType:"fa-light"})

  //$: console.log($conf)

  /*$: if(code) $conf.code=code
  $: if($conf.code && $conf.code.type) setRoute("/" + $conf.code.type, null, null, true, "U") //with notreload
  $: console.log("Unity Route is : ", $routeU)
  $: activity=$conf.code
  $: if(activity && activity.u && !activity.u[activity.type]) activity.u[activity.type]={}
  */

  $: activity=code || $conf.code
  $: if(activity && activity.u && !activity.u[activity.type]) activity.u[activity.type]={}
  $: if(activity && activity.type) $routeU="/" + activity.type

  let googleApiKey="AIzaSyDQ7syuczOC1R7IRe2FL9Bh-TYNKdqlOlc"
  let location="tour eiffel", srcAddressEncoded
  $: if(isRoute($routeU,"/locator") && location) srcAddressEncoded=encodeURI(`https://www.google.com/maps/embed/v1/directions?origin=current+location&destination=${location}&mode=walking&key=${googleApiKey}`)

  //initialize splash page && reseller logo
  $: splash=isRoute($routeU, ['tour','/'],"U")
  $: reseller=isRoute($routeU, ['tour','/'],"U")
  $: logo=cdn($conf.logo)
  $: icon=cdn($conf.icon)
  setTimeout(() => {
    splash=false
  }, 600);
  
  function checkImg(img){
    let emptyImg="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkMAYAADkANVKH3ScAAAAASUVORK5CYII="
    return img?img:"https://picsum.photos/300/200?random="+Math.random()//emptyImg
  }
  
  let social=[
    { name:"twitter", url:"https://twitter.com/", icon:"fa-brands fa-x-twitter", prefix:"@"},
    { name:"instagram", url:"https://www.instagram.com/", prefix:""},
    { name:"linkedin", url:"", icon:"fa-brands fa-linkedin", prefix:""},
    { name:"snapchat", url:"https://www.snapchat.com/add/", prefix:"@"},
    { name:"tiktok", url:"https://tiktok.com/@", icon:"fa-brands fa-tiktok", prefix:"@"},
    { name:"pinterest", url:"https://www.pinterest.fr/", prefix:""},
    { name:"youtube",  url:"", prefix:""},
    { name:"whatsapp", url:"https://wa.me/", prefix:""},
    { name:"flickr", url:"https://www.flickr.com/photos/", icon:"fa-brands fa-flickr", prefix:""},
    { name:"facebook", url:"", prefix:""},
    { name:"url", url:"", icon:"fa-solid fa-square-w", prefix:""},
    { name:"email", url:"mailto:", icon:"fa-solid fa-square-envelope", prefix:""},
    { name:"phone", url:"tel:", icon:"fa-solid fa-square-phone", prefix:""}
  ]

  function cleanSocialAccount(account="", encode=false){
    account = account.replace(/^@/, "");
    account = account.replace(/^#/, "");
    if(encode) account=encodeURI(account)
    return account
  }
  
  /* a supprimer*/
  /*activity.points=[
		{ name: "", location: "48.683368,1.746219" },
		{ name: "", location: "48.686435,1.738467" },
		{ name: "", location: "48.683793,1.724616" },
		{ name: "", location: "48.673705,1.722254" },
		{ name: "", location: "48.673648,1.728567" },
		{ name: "", location: "48.677672,1.736425" },
	]*/
  /* fin de à supprimer */

  let fields={}

  async function submit(item){
    if(item.action){
      if(item.action.do && item.action.do[fields[item.action.on]]) {
        openHref(item.action.do[fields[item.action.on]], {location:!isDesign})
      }
    }
    if(item.action){
      if(item.action.cookie) {
        for(let property in fields) {
          setCookie(item.action.cookie + '_'+ property, fields[property], 5)
        }
      }
    }

    if(isDesign || isPreview) fields._test=true
    await api("/public/record", { codeId: activity.id, fields: fields })
    return true
  }

  function makeVcard(card){
    return btoaUnicode(JSON.stringify(card))
  }

  let clickedToAuthPlay,ended,isVideo,video, isAudio,audio, codeRead,replay

  $: if(activity && activity.u && activity.u.custom && activity.u.custom.valueFromcookie) {
    for(let property in fields) {
      if(!fields[property]) fields[property]=getCookie(activity.u.custom.valueFromcookie + '_'+ property)
    }
  }



</script>

{#if isPreview }<div class="pulse topMsg"><I18n>code--Ispreview top unity msg</I18n></div>{/if}
{#if false && isDesign }<div class="pulse topMsg"><I18n>code--Isdesign top unity msg</I18n></div>{/if}

<!-- TEST new function unity https://eqri.to/u/008hello (configured in server.js u route) -->
<Route isU path="/@008hello">
  008 Hello
  <PdfImg/>
</Route>


<div class="unity" class:unityDesign={isDesign}>

  <!-- UNITY TOUR -->
  <!-- start -->
  <Route isU path="/tour/start">

    <Navbar {activity}/>
    <div class="row g-0">
      <div class="mx-auto text-center col">
        <div class="mt-2 mx-4 d-flex justify-content-between">
          <div class="neutral large">
            <i class="fa-light fa-headphones"></i>
            {#if activity && activity.u.tour.codeIds && activity.u.tour.codeIds.length>0}
              <span>{activity.u.tour.codeIds.length} ÉTAPES</span>
              {#if activity.u.tour.duration} <span>- {activity.u.tour.duration}</span>{/if}
              {#if activity.u.tour.distance } <span>- {activity.u.tour.distance}</span>{/if}
              {#each activity.u.tour.codeIds as point}
                <i class="ms-2 fa-solid fa-map-marker-alt" class:neutral={point!=code.id} class:currentPoint={point==code.id}></i>
              {/each}
            {/if}
          </div>
          <i class="clickable fa-solid fa-map"></i>
        </div>      
        <div class="h3">{i18n(activity.u.tour.title)}</div>
        <p>{@html i18nBr(activity.u.tour.intro)}</p>

        
        <div class="display-4 mb-1">{i18n(activity.u.tour.title)}</div>

        <div class="bouncing1">
          <i on:click={clickedToAuthPlay} style="font-size:8em;" class="hover fa-regular fa-play-circle"></i>
        </div>


        {#if activity.notFirst}
          <div class="neutral">
            <i class="fa-light fa-info-circle text-danger"></i> Vous n'êtes pas au début du parcours
            <!--
            <i class="fa-light fa-walking circle pointer"></i> Rejoindre
            -->
          </div>
        {/if}

        <Pub {activity}/>
      </div>
    </div>
    <Footer {activity}/>
  </Route>


  <!-- step --> 
  <Route isU path="/tour/step">
    <Navbar {activity}/>
    <div class="row g-0">

      {#if i18n(code.tour.intro)}
        <p in:fade>{@html i18nBr(code.tour.intro)}</p>
      {/if}

      {#if true}
        <video class:d-none={!isVideo} 
          class="video" playsinline bind:this={video} on:ended={ended} 
          src={isVideo} controls controlsList="nodownload">
          <track kind="captions">
        </video>
        
        <audio class:d-none={!isAudio} 
          class="audio" bind:this={audio} on:ended={ended} 
          src={isAudio} controls controlsList="nodownload">
          <track kind="captions">
        </audio>
      {/if}

      <div class="row g-0">
        <div on:click={replay} class="col text-center pointer"><i class="fa-light fa-localborder fa-repeat"></i> Rejouer</div>

        <div on:click={ended} class="col text-center pointer bouncing1"><i class="fa-light fa-localborder fa-forward"></i> Etape suivante</div>
        <!--div on:click={terminateTour} class="col-5 m-auto btn btn-sm btn-danger"><i class="fa-light fa-times"></i> Terminer le tour</div-->
      </div>

      {#if i18n(activity.u.tour.transcription)}
        <div class="accordion" id="accordion">
          <div class="accordion-item">
            <div class="accordion-header" id="headingOne">
              <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTranscription" aria-expanded="true" aria-controls="collapseTranscription">
                <i class="fa-light fa-ear fa-fw"></i>&nbsp;{i18n('Transcription')}
              </button>
            </div>
            <div id="collapseTranscription" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
              <div class="accordion-body">
                {@html i18nBr(activity.u.tour.transcription)}
              </div>
            </div>
          </div>
        </div>
      {/if}
    </div>
    <Footer {activity}/>
  </Route>

  <!-- map -->
  <Route isU path="/tour/map">
    <Navbar {activity}/>
    <div class="row g-0">
      <div class="row mt-4 g-0">
        <div class="text-center">  
          <div class="go"><i class="fa-solid fa-walking"></i> Dirigez-vous vers</div>
          <div class="display-4 mb-1">{activity.next.name}</div>
          <div>{activity.next.location}</div>
        </div>
      </div>
      <Map/>
    </div>
    <Footer {activity}/>
  </Route>


  <!-- scanner -->
  <Route isU path="/tour/scanner">
    <Navbar {activity}/>
    <div class="row g-0">
      <div class="row mt-4 g-0">
        <div class="mx-auto text-center col">
          <div class="h2"><i class="fa-light fa-search"></i> Où est le QR code ?</div>
          Trouvez et scannez le QR code de l'étape pour continuer le parcours
          <Scanner bind:codeRead/>
        </div>
      </div>
      <div class="text-center" style="position: relative;top: -35px;">  
        <button type="button" on:click={()=>{}} class="btn btn-outline-danger round" style="background-color: #F5F2F2;">
          <i class="fa-solid fa-step-backward"></i> Revenir à la carte
        </button>
      </div>
    </div>
    <Footer {activity}/>
  </Route>


  <!-- final -->
  <Route isU path="/tour/final">
    <Navbar {activity}/>
    <div class="row g-0">
      <div class="mx-auto my-auto text-center col">
        <p>C'était la dernière étape.</p>
        <p>{i18n(activity.u.tour.endMessage)}</p>
        {#if activity.u.tour.scratch}
          <hr>
          <p>{@html i18nBr(activity.u.tour.scratchMessage)}</p>
          <div class="d-flex justify-content-center">
            <ScratchCard  
              {isDesign}
              isLottery={activity.u.tour.isLottery}
              lotteryChance={activity.u.tour.lotteryChance}
              scratchMessage={activity.u.tour.scratchMessage}
              imageCoverCdn={activity.u.tour.imageCoverCdn}
              isImageCover={activity.u.tour.isImageCover}
              winCode={activity.u.tour.winCode}
              content={activity.u.tour.content}
              winContent={activity.u.tour.winContent} 
              looseContent={activity.u.tour.looseContent}
            />
          </div>
        {/if}
        <hr>
        <button type="button" class="btn btn-info" on:click={()=>setRoute("/tour/all", null, null, null, "U")}>Une autre visite ?</button>
        <button type="button" class="btn btn-info" on:click={()=>setRoute("/tour/comment", null, null, null, "U")}>Un commentaire ?</button>
        <Pub {activity}/>
      </div>
    </div>
    <Footer {activity}/>
  </Route>

  <!-- allTours -->
  <Route isU path="/tour/select">
    <Navbar {activity}/>
    <div class="row g-0">
      selectTour
      <!-- selectTour content -->
    </div>
    <Footer {activity}/>
  </Route>

  <!-- allTours -->
  <Route isU path="/tour/all">
    <Navbar {activity}/>
    <div class="row g-0">
      show all tour
      <!-- allTour content -->
    </div>
    <Footer {activity}/>
  </Route>

  <!-- allTours -->
  <Route isU path="/tour/comment">
    <Navbar {activity}/>
    <div class="row g-0">
      comment
    <!-- comment content -->
    </div>
    <Footer {activity}/>
  </Route>

  <!-- END UNITY TOUR -->



  <!-- CUSTOM -->
  <Route isU path="/custom">
    <Navbar {activity}/>
    <Content {activity}>
      <CustomRender bind:items={activity.u.custom.items} page={activity.u} bind:fields {submit} bind:editItemIndex {isDesign} {isPreview}/>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- APP -->
  <Route isU path="/app">
    {#if activity.u.app.isDirect && isDesign}
      <div class="text-center app-badgeStore">
        <a href="https://play.google.com/store/apps/details?id={activity.u.app.androidId}" target="_blank">
          <img style="width:30%;" alt="" src="https://cdn.eqrito.com/google-play-badge.svg">
        </a>
        <a href="https://apps.apple.com/app/{activity.u.app.iosId}" target="_blank">
          <img style="width:30%;" alt="" src="https://cdn.eqrito.com/apple-app-store-badge.svg">
        </a>
      </div>
      <img class="img-fluid" alt="" src="https://cdn.eqrito.com/assets/application_stores.png">
    {:else if activity.u.app.isDirect && !isDesign}
      <div class="text-center m-4 h4">
        <I18n>Download</I18n>
        {activity.u.app.name}
      </div>
      <div class="text-center app-badgeStore">
        <a href="https://play.google.com/store/apps/details?id={activity.u.app.androidId}" target="_blank">
          <img style="width:50%;" alt="" src="https://cdn.eqrito.com/google-play-badge.svg">
        </a>
        <a href="https://apps.apple.com/app/{activity.u.app.iosId}" target="_blank">
          <img style="width:50%;" alt="" src="https://cdn.eqrito.com/apple-app-store-badge.svg">
        </a>
      </div>
    {:else}
      <Navbar {activity}/>
      <Content {activity}>
        <div class="text-center app-badgeStore">
          {#if !platform('iOS') && activity.u.app.androidId}
            <a href="https://play.google.com/store/apps/details?id={activity.u.app.androidId}" target="_blank">
              <img style="width:50%;" alt="" src="https://cdn.eqrito.com/google-play-badge.svg">
            </a>
          {/if}
          {#if !platform('android') && activity.u.app.iosId}
            <a href="https://apps.apple.com/app/{activity.u.app.iosId}" target="_blank">
              <img style="width:50%;" alt="" src="https://cdn.eqrito.com/apple-app-store-badge.svg">
            </a>
          {/if}
        </div>
      </Content>
      <Footer {activity}/>
    {/if}
  </Route>


  <!-- CARD -->
  <Route isU path="/card">
    <Navbar {activity}/>
    <Content class="text-center" {activity}>
      <div class="container">
        {#if activity.u.card.photoCdn}
          <div class="mt-4 mx-auto circular-portrait"><img src={cdn(activity.u.card.photoCdn)} alt=""/></div>
        {:else}
          <div class="mt-4 mx-auto circular-portrait"><img src={cdn("assets/placeholder-portrait.png")} alt=""/></div>
        {/if}
        {#if activity.u.card.organization}  <div class="m-2 h1">{@html nl2br(activity.u.card.organization)}</div>{/if}
        {#if activity.u.card.lastname || activity.u.card.firstname} <div class="m-2 h4">{u(activity.u.card.firstname)} {u(activity.u.card.lastname)}</div>{/if}
        {#if activity.u.card.title}         <div class="m-2 h6">{@html nl2br(activity.u.card.title)}</div>{/if}

        <div class="mx-3 my-3 col">
          <a role="button" class="rippleIt aashakeIt w-100 btn btn-primary btn-lg" 
            style="border-color: {activity.u.colorButtonBg}; 
            color:{activity.u.colorButtonText}; 
            background-color:{activity.u.colorButtonBg};"
            atarget="_blank" href="{`/api/v4/public/vcard?payload=${makeVcard(activity.u.card)}`}">
            {i18n("unity--card add me")}
          </a>



 
          {#if activity.u.card.cell}
            <a role="button" class="w-50 btn btn-primary btn mt-2" 
              style="border-color: {activity.u.colorButtonBg}; 
              color:{activity.u.colorButtonText}; 
              background-color:{activity.u.colorButtonBg};"
              href="sms:{activity.u.card.cell}?body={`${i18n("unity--card sms me")} (To: ${u(activity.u.card.firstname)} ${u(activity.u.card.lastname)} - ${u(activity.u.card.organization)} - ${u(activity.u.card.title)})`}">
              <I message-sms/>
            </a>
          {/if}
          {#if activity.u.card.email}
            <a role="button" class="w-50 btn btn-primary btn mt-2" 
              style="border-color: {activity.u.colorButtonBg}; 
              color:{activity.u.colorButtonText}; 
              background-color:{activity.u.colorButtonBg};"
              href="mailto:{activity.u.card.email}?subject={`${i18n("unity--card mail me")}`}">
              <I envelope/>
            </a>
          {/if}
        </div>   

        {#if activity.u.card.website}       <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="browser"/><a class="pointer" style="color:{activity.u.colorLink};" href={activity.u.card.website}>{activity.u.card.website}</a></div>{/if}
        {#if activity.u.card.email}          <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="at"/><a class="pointer" style="color:{activity.u.colorLink};" href="mailto:{activity.u.card.mail}">{activity.u.card.email}</a></div>{/if}
        {#if activity.u.card.phoneWork}     <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="phone-office"/><a class="pointer" style="color:{activity.u.colorLink};" href="tel:{activity.u.card.phoneWork}">{activity.u.card.phoneWork}</a></div>{/if}
        {#if activity.u.card.phoneHome}     <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="phone"/><a class="pointer" style="color:{activity.u.colorLink};" href="tel:{activity.u.card.phoneHome}">{activity.u.card.phoneHome}</a></div>{/if}
        {#if activity.u.card.cell}          <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="mobile"/><a class="pointer" style="color:{activity.u.colorLink};" href="tel:{activity.u.card.cell}">{activity.u.card.cell}</a></div>{/if}
        {#if activity.u.card.faxWork}       <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="fax"/><a class="pointer" style="color:{activity.u.colorLink};" href="tel:{activity.u.card.faxWork}">{activity.u.card.faxWork}</a></div>{/if}
        {#if activity.u.card.faxHome}       <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="fax"/><a class="pointer" style="color:{activity.u.colorLink};" href="tel:{activity.u.card.faxHome}">{activity.u.card.faxHome}</a></div>{/if}

        {#if activity.u.card.street}        <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="road"/>{activity.u.card.street}</div>{/if}
        {#if activity.u.card.zipcode || activity.u.card.city} <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="city"/>{u(activity.u.card.zipcode)} {u(activity.u.card.city)}</div>{/if}
        {#if activity.u.card.country ||activity.u.card.state} <div class="m-2" style="color:{activity.u.colorLink};"><I me icon="flag"/>{u(activity.u.card.state)} {u(activity.u.card.country)}</div>{/if}
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- EVENT -->
  <Route isU path="/event">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row g-0">
        <div class="mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="eventRSVP1">
            <label class="form-check-label" for="eventRSVP1">
              Je serais présent
            </label>
          </div>
        </div>
        <div class="mb-3">
          <label for="event" class="form-label">Votre email (facultatif)</label>
          <input type="email" class="form-control" id="eventRSVP2" 
          placeholder="">
        </div>
        <div class="mb-3">
          <label for="event" class="form-label">Votre nom (facultatif)</label>
          <input type="text" class="form-control" id="eventRSVP3" 
          placeholder="">
        </div>
        <div class="mb-3">
          <label for="eventRSVP4" class="form-label">Commentaire/question(facultatif)</label>
          <textarea required class="form-control" id="eventRSVP4" rows="5"></textarea>
        </div>
        <button type="button" on:click={()=>{}} class="w-100 btn btn-primary">Envoyer</button>
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- SOCIAL -->
  <Route isU path="/social">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row mx-1 mt-3">
        {#each social as social}
          {#if activity.u.social[social.name]}
            <div class="{activity.u.social.length==1?'col-12':'col-6'} pointer mb-4" class:col-lg-3={!isDesign}>
              <a  style="color:{activity.u.colorLink};" class="text-center" target="_blank" href="{social.url}{cleanSocialAccount(activity.u.social[social.name],true)}">
                <div class="fs-3"><i class="fa-4x {social.icon?social.icon:"fa-brands fa-"+social.name+"-square"}"></i></div>
                <!--div>{activity.u.social[social.name + "Name"] || (social.prefix + cleanSocialAccount(activity.u.social[social.name]))}</div-->
                <div>{activity.u.social[social.name + "Name"]}</div>
              </a>
            </div>
          {/if}
        {/each}
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- LEAD -->
  <Route isU path="/lead">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row g-0">
        <div class="mb-3">
          <label for="lead1" class="form-label">Votre email (facultatif)</label>
          <input type="email" class="form-control" id="lead1" 
          placeholder="">
        </div>
        <div class="mb-3">
          <label for="lead2" class="form-label">Votre nom (facultatif)</label>
          <input type="text" class="form-control" id="lead2" 
          placeholder="">
        </div>
        <div class="mb-3">
          <label for="lead3" class="form-label">Commentaire/question(facultatif)</label>
          <textarea required class="form-control" id="lead3" rows="5"></textarea>
        </div>
        <button type="button" on:click={()=>{}} class="w-100 btn btn-primary">Envoyer</button>
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- COUPON -->
  <Route isU path="/coupon">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row g-0">
        <div class="container mt-5">
          <div class="row d-flex justify-content-center">
            <div class="col-md-5">
              <div class="card">
                <div class="text-right p-2"> <img src="https://i.imgur.com/w68MQc4.png" width="120" alt="..."> </div>
                <div class="text-center"> <small class="text-uppercase flat">Flat</small> </div>
                <div class="d-flex justify-content-center px-2">
                  <div class="d-flex flex-row">
                      <h1 class="mt-0 off">50% OFF</h1> <sup class="mt-2 text-primary star">*</sup>
                  </div>
                </div>
                <div class="line">
                  <hr>
                </div>
                <div class="text-center mb-5"> <span class="text-uppercase">Valid till 23 august</span> </div>
                <div class="text-right p-1"> <small>*T&C APPLY</small> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- LOCATOR -->
  <Route isU path="/locator">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row g-0">
        <div class="col-2 scrollY"> zzzhello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello
          zzzhello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello
          zzzhello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello<br>hello
        </div>
        <div class="col-10">
          <div class="google-maps">
            <iframe
              title="map"
              src="{srcAddressEncoded}"
              width="600"
              height="900"
              style="border:0;"
              allowfullscreen=false
              loading="lazy">
            </iframe>
          </div>
        </div>
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- map -->
  <Route isU path="/map">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="m-2">{activity.u.map.location}</div>
      <iframe title="" width="100%" style="height:90vh;" src="https://maps.google.com/maps?t=m&z=10&iwloc=B&output=embed&q=${encodeURI(activity.u.map.location)}" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </Content>
    <!--https://maps.google.com/maps?hl=en&q=48.9155807%2C2.2715676&ie=UTF8&t=m&z=10&iwloc=B&output=embed-->
    <Footer {activity}/>
  </Route>
 

  <Route isU path="/arboretum">
    <Navbar {activity}/>
    <Content {activity}>
      {#if activity.u.arboretum.photoCdn}
        <img src={cdn(activity.u.arboretum.photoCdn)} alt="arboretum" class="img-fluid">
      {/if}
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">{i18n('unity--arboretum botanic')}</div>
          <div class="col-6">{activity.u.arboretum.botanic}</div>
        </div>
        <div class="row">
          <div class="col-6">{i18n('unity--arboretum vernacular')}</div>
          <div class="col-6">{activity.u.arboretum.vernacular}</div>
        </div>
  
        <div class="row">
          <div class="col-6">{i18n('unity--arboretum family')}</div>
          <div class="col-6">{activity.u.arboretum.family}</div>
        </div>
        <div class="row">
          <div class="col-6">{i18n('unity--arboretum origin')}</div>
          <div class="col-6">{activity.u.arboretum.origin}</div>
        </div>
        <div class="row">
          <div class="col-6">{i18n('unity--arboretum planting date')}</div>
          <div class="col-6">{dateTimeFormat(activity.u.arboretum.plantingDate,'dmy')}</div>
        </div>
        <div class="row">
          <div class="col-6">{i18n('unity--arboretum flowering')}</div>
          <div class="col-6">{activity.u.arboretum.flowering}</div>
        </div>
        <div class="row">
          <div class="col-6">{i18n('unity--arboretum colors')}</div>
          <div class="col-6">{activity.u.arboretum.colors}</div>
        </div>
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- MEDIAS -->
  <Route isU path="/media">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row g-0">
        {#each asArray(activity.u.media) as media}
          {#if media.type=="video"}
            <video src={media.src} alt="media"><track kind="captions"/></video>

          {/if}
          {#if media.type=="audio"}
            <audio src={media.src} alt="media"><track kind="captions"/></audio>

          {/if}
          {#if media.type=="img"}
            <img src={media.src} alt="media"/>

          {/if}
        {/each}
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- SURVEY -->
  <Route isU path="/survey">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row g-0">
        {#each asArray(activity.u.survey.items) as item}
          {#if item.type=="rating"}
            <i class="fa-regular fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <i class="fa-regular fa-star"></i>
            <i class="fa-regular fa-star"></i>
          {/if}
        {/each}
      </div>
    </Content>
    <Footer {activity}/>
  </Route>


  <!-- GAME -->
  <Route isU path="/game">
    <Navbar {activity}/>
    <Content {activity}>
      <div class="row g-0">
        <div class="d-flex justify-content-center">

          <ScratchCard  
            {isDesign}
            isLottery={activity.u.game.isLottery}
            lotteryChance={activity.u.game.lotteryChance}
            scratchMessage={activity.u.game.scratchMessage}
            imageCoverCdn={activity.u.game.imageCoverCdn}
            isImageCover={activity.u.game.isImageCover}
            winCode={activity.u.game.winCode}
            content={activity.u.game.content}
            winContent={activity.u.game.winContent} 
            looseContent={activity.u.game.looseContent}
          />
        </div>
      </div>
      {#if activity.u.game.instruction}
        <div class="my-2 text-center">{@html i18nBr(activity.u.game.instruction)}</div>
      {/if}
    </Content>
    <Footer {activity}/>
  </Route>


  <Route isU path="/@qrtest">
    <Msg msg="unity--Msg successful read of this qr" color="success" icon="thumbs-up"/>
  </Route>

  <Route isU path="/@notvalid">
    <Msg msg="unity--Msg this qr is not open" color="danger" icon="xmark-large" isurl/>
  </Route>

  <Route isU path="/@tooearly">
    <Msg msg="unity--Msg this qr is not yet open" color="info" icon="clock"/>
  </Route>

  <Route isU path="/@toolate">
    <Msg msg="unity--Msg this qr is not more open" color="danger" icon="triangle-exclamation"/>
  </Route>

  <Route isU path="/@notype">
    <Msg msg="unity--no content qr" color="danger" icon="triangle-exclamation"/>
  </Route>

  <Route isU path="/@protected">
    <Msg msg="unity--msg this qr is protected" color="warning" icon="lock-keyhole" ispassword {activity}/>
  </Route>



  <!-- noCode -->
  <Route isU path={["/@notexist","/",""]}>
    <Msg msg="unity--Msg this qr doesn't exist" color="danger" icon="triangle-exclamation"/>
  </Route>
</div>


<!-- Spash screen -->
{#if splash && !isDesign}
  <div out:fade class="fullScreen splash" style="background-image: url({encodeURI(icon)});">
    <div class="fixed-bottom mb-1">
			<div class="text-center small">© {thisYear()}	- v{version} - {fuc($conf.htmlRoot)}
			</div>
		</div>
  </div>
{/if}

<!-- reseller logo on bottom left -->
{#if reseller && !isDesign}
  <div class="reseller">
    <a href="https://eqrito.com" target="_new"><img height="20px" alt="" src="{logo}"></a>
  </div>
{/if}

<div class="version">v{version}</div>

<style>
  .unity {
    display: flex;
    flex-direction:column;
    position:fixed;
    top: -1px;
    left:0;
    right:0;
    bottom:0;
    height: 100%;
    overflow: hidden;
  }

  .unityDesign {
    position:absolute;
  }

  .btn:hover{
    opacity:0.8;
  }

  .btn-primary:visited {
    color: white;
  }
  .splash {
    z-index:9000;
    background-color: white;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
  }
  .reseller{
    z-index:8000;
    position: fixed;
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: 5px 9px;
    -moz-transform-origin: 5px 9px;
    -ms-transform-origin: 5px 9px;
    -o-transform-origin: 5px 9px;
    transform-origin: 0px 4px;
  }
  .version{
    z-index:8000;
    position: fixed;
    bottom: 0;
    opacity:0.5;
    right:30px;
    font-size:8px;
    color: white;
    mix-blend-mode: difference;
  }

  .app-badgeStore img{
    height: 80px;
    margin: 5px;
  }

  .card {
    border: none;
    position: relative
  }

  .circular-portrait { position: relative; width: 200px; height: 200px; overflow: hidden; border-radius: 50%; }
  .circular-portrait img { width: 100%; height: auto; }

  .off {
      font-size: 60px;
      margin-top: -16px !important;
      color: blue
  }

  .flat {
      font-size: 20px
  }

  .star {
      margin-top: 25px !important;
      font-size: 28px
  }

  .line {
      padding-right: 8rem !important;
      padding-left: 8rem !important
  }

  .line hr {
      height: 2px
  }


  .google-maps{
    position:relative;
  }

  .google-maps iframe{
    height:500px;
    width:100%;
  }
  .topMsg{
    font-size:0.8em;
    z-index: 99999999;
    position: absolute;
    left: 40%;
    color: black;
    background-color: white;
  }

  :global(.unity .G_Route){
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
  }



  .rippleIt {
    animation: ripple 2.5s infinite ease-out;
  }


  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(200, 200, 200, 0.7), 0 0 0 0 rgba(200, 200, 200, 0.7), 0 0 0 0 rgba(200, 200, 200, 0.7);
    }
    40% {
      box-shadow: 0 0 0 5px rgba(200, 200, 200, 0), 0 0 0 15px rgba(200, 200, 200, 0), 0 0 0 20px rgba(200, 200, 200, 0);
    }
    80% {
      box-shadow: 0 0 0 15px rgba(200, 200, 200, 0), 0 0 0 20px rgba(200, 200, 200, 0), 0 0 0 25px rgba(200, 200, 200, 0);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(200, 200, 200, 0), 0 0 0 25px rgba(200, 200, 200, 0), 0 0 0 30px rgba(200, 200, 200, 0);
    }
  }
</style>